import { createI18n } from 'vue-i18n'

// import langEnCommon from '../prismaccess-common/src/locales/en.json'
// import langFrCommon from '../prismaccess-common/src/locales/fr.json'
// import langDeCommon from '../prismaccess-common/src/locales/de.json'


function loadLocaleMessages () {
  const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const localeCommon = require.context('../prismaccess-common/src/locales/', true, /[A-Za-z0-9-_,\s]+\.json$/i)

  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]      
      if(localeCommon.keys().includes(key)){
        messages[locale] = {...localeCommon(key), ...locales(key)}
      }else{
        messages[locale] = locales(key)
      }      
    }
  })
  return messages
}

export default createI18n({
  locale: localStorage.getItem('language'),
  fallbackLocale: localStorage.getItem('language'),
  messages: loadLocaleMessages(),
  formatFallbackMessages: true,
  silentTranslationWarn: true
})

 
 