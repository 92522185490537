import Vuex from 'vuex'
import auth from "../prismaccess-common/src/store/auth.module"
import header from "../prismaccess-common/src/store/header.module"
import feedback from "../prismaccess-common/src/store/feedback.module"


export default Vuex.createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    header,
    feedback
  }
});
