import api from './api'; 
import authHeader from './auth-header';  

class UserService { 

    send_feedback(param){
        return api
        .post('users/send_feedback', param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            return Promise.reject(error);
        });
    }   
    
}

export default new UserService();
