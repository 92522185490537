import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user : user }
  : { status: { loggedIn: false }, user: null };
  const getDefaultState = () => {
    return {
        ...initialState,       
        load: false,
        list_country: [],
    }
}
const state = getDefaultState();

const getters = {
    user: state => state.user,
    list_country: state => state.list_country
};

const actions = {
    register({ commit }, user) {
        commit('setLoad', true);
        return AuthService.register(user).then(
            res => { 
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        );
    },
    login({ commit }, user) {
        return AuthService.login(user).then(
            user => { 
                commit('loginSuccess', user);
                return Promise.resolve(user);
            },
            error => {
                commit('loginFailure');
                return Promise.reject(error);
            }
        );
    },
    login_auto({ commit }, user) {
        return AuthService.login_auto(user).then(
            user => { 
                commit('loginSuccess', user);
                return Promise.resolve(user);
            },
            error => {
                commit('loginFailure');
                return Promise.reject(error);
            }
        );
    },
    logout({ commit }) {
        return AuthService.logout().then(
            res => {
                commit('logout');
                return Promise.resolve(res);
            },
        )  
    },
    forgot_password({ commit }, email) {
        commit('setLoad', true);
        return AuthService.forgot_password(email).then(
            res => { 
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        );
    },
    reset_password({ commit }, param) {
        commit('setLoad', true);
        return AuthService.reset_password(param).then(
            res => { 
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        );
    },
    get_list_country({ commit }){
        return AuthService.get_list_country().then(
            res => {
                commit('list_country', res);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        );
    }, 
};
const mutations = {
    loginSuccess(state, user) {
        state.status.loggedIn = true;
        state.user = user;
    },
    loginFailure(state) {
        state.status.loggedIn = false;
        state.user = null;
    },
    logout(state) {
        state.status.loggedIn = false;
        state.user = null;
    },
    registerSuccess(state) {
        state.status.loggedIn = false;
    },
    registerFailure(state) {
        state.status.loggedIn = false;
    },
    setLoad(state, load) {
        state.load = load;
    },
    list_country(state, list_country){
        state.list_country = list_country
    }, 
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}