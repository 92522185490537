const state = {
    openMenu : false,
    openBoxSearch : false,  
};

const getters = {
    openMenu: state => state.openMenu,
    openBoxSearch: state => state.openBoxSearch
};

const actions = {
    setOpenMenu({ commit }, payLoad) {
        commit('setOpenMenu', payLoad);               
    },
    setOpenBoxSearch({ commit }, payLoad) {
        commit('setOpenBoxSearch', payLoad);               
    },
};
const mutations = {
    setOpenMenu(state , payLoad){
        if(payLoad && state.openBoxSearch){
            state.openBoxSearch = false
        }
        state.openMenu = payLoad;
    },
    setOpenBoxSearch(state , payLoad){
        if(payLoad && state.openMenu){
            state.openMenu = false
        }
        state.openBoxSearch = payLoad;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}