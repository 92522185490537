import { createRouter, createWebHistory } from 'vue-router';
import { isLogin } from '../utils';

const routes = [
    {
        path: '/',
        name: 'home',
        alias: '/home',
        component: () => import(/* webpackChunkName: "simple" */ '../views/home.vue')
    }, 
    {
        path: '/test',
        name: 'test',
        component: () => import(/* webpackChunkName: "simple" */ '../views/test.vue')
    }, 
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../prismaccess-common/src/views/Auth/Login.vue')
    },
    {
        path: '/register',
        alias: '/register/',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '../prismaccess-common/src/views/Auth/Register.vue')
    },     
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: "forgot_password" */ '../prismaccess-common/src/views/Auth/ForgotPassword.vue')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "reset_password" */ '../prismaccess-common/src/views/Auth/ResetPassword.vue')
    },
    { 
        path: "/:catchAll(.*)",
        name: 'NotFound', 
        component: () => import(/* webpackChunkName: "notFound" */ '../prismaccess-common/src/views/notFound.vue')
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,

    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
     
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login', '/register', '/register/', '/forgot-password', '/reset-password'];    
    const authRequired = !publicPages.includes(to.path);
    
    if (authRequired && !isLogin()) {
        localStorage.redirectTo = to.path
        return next('/login');
    }   

    next();
})

export default router