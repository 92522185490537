import UserService from '../services/feedback.service';

const state = {   
    load: false,
    sendFeedbackSuccess : false,    
    feedback_msg : ""
}

const getters = {
    load: state => state.load,
    sendFeedbackSuccess : state => state.sendFeedbackSuccess,
    feedback_msg  : state => state.feedback_msg    
};

const actions = {  
    send_feedback({ commit}, payLoad) { 
        commit('setLoad', true);
        return UserService.send_feedback(payLoad).then(
            res => {  
                console.log('send_feedback res...',res)
                commit('setLoad', false);
                commit('sendFeedbackSuccess', res.sent);     
                commit('setfeedback_msg', res.reason); 
                return Promise.resolve(res);
            },
            error => {                
                return Promise.reject(error);
            }
        )
    },


    
};
const mutations = {
    setLoad(state, load) {
        state.load = load;
    },
    sendFeedbackSuccess(state, param) {
        state.sendFeedbackSuccess = param;
    },
    setfeedback_msg(state,param){
        state.feedback_msg = param;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

