import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locales'


if(!localStorage.getItem('language')){
  localStorage.setItem('language', 'fr')
}

// createApp({
//   // i18n,
//   render: () => h(App)
// }).use(router).use(store).use(i18n).mount('#app')


const app = createApp(App)
app.use(i18n)
app.use(router)
app.use(store)
app.mount('#app')

//createApp(App).use(router).use(store).use(i18n).mount('#app')